/**
 * App routes configuration
 */
export const AppRoutes = {
  Default: '/',
  Wildcard: '**',
  CallbackTpay: 'callback_tpay',
  Blank: '',
  Homepage: '',
  ForCompanies: 'for-companies',
  ForRestaurants: 'for-restaurants',
  Blog: 'blog',
  BlogPost: 'blog/:category/:slug',
  NotFound: 'not-found',
  Auth: 'auth',
  SignIn: 'sign-in',
  SignUp: 'sign-up',
  ResetPassword: 'reset-password',
  NewPassword: 'new-password',
  Verify: 'verify',
  ConfirmPhoneNumber: 'confirm-phone-number',
  SetPhoneNumber: 'set-phone-number',
  id: ':id',
  MyAccount: 'my-account',
  Settings: 'settings',
  Configuration: 'configuration',
  Orders: 'orders',
  Bills: 'bills',
  BillsList: 'list',
  BillDetails: ':restaurant/:id',
  Reservations: 'reservations',
  Friends: 'friends',
  Addresses: 'addresses',
  AddressesList: 'list',
  ReservationsList: 'list',
  ReservationsDetails: 'details/:id',
  AddressesAdd: 'add',
  AddressesEdit: 'edit/:id',
  Points: 'points',
  Restaurants: 'restaurants',
  RestaurantsList: 'list',
  Restaurant: 'details/:hostname',
  AlcoholConfigurator: 'alcohol-configurator/:hostname',
  Cart: ':hostname/cart',
  CartDetails: 'details',
  CartSummary: 'summary/:id',
  Terms: 'terms-of-service',
  Privacy: 'privacy-policy',
  Cookies: 'cookies-policy',
  LegalInformations: 'legal-informations',
};
